import { gql } from 'apollo-boost'

export default gql`
  mutation LoginSocial(
    $accessToken: String!
    $type: SocialPlatform!
  ) {
    loginSocial(
      accessToken: $accessToken
      type: $type
    ) {
      token
      user {
        id
        plans {
          id
          product {
            id
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`
