import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { get } from 'lodash'

import useVerifyRole from '../../hooks/verifyRole'
import SignInWithFacebook from '../../components/SignInWithFacebook'
import SignInWithApple from '../../components/SignInWithApple'
import { Form } from '../../_shared/components'
import useSocialLogin from '../../hooks/sogicalLogin'
import SEO from '../../components/SEO'
import { client } from '../../apollo/client'
import useLogin from '../../hooks/login'

export default function Page() {
  // Verify that the user is an admin. Redirect away from admin if non-admin, or login if not signed in
  const { loading, hasRole } = useVerifyRole(['admin'], false)
  const [authType, setAuthType] = useState(null)
  const errorState = useState(null)
  const [error, setError] = errorState
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { token: loginToken, login } = useLogin(
    errorState,
    setAuthType,
    email,
    password,
  )

  const { token: socialLoginToken, login: socialLogin } = useSocialLogin(
    errorState,
    setAuthType,
  )

  // Persist token to session storage
  useEffect(() => {
    const token = loginToken || socialLoginToken

    async function setToken() {
      if (!window.sessionStorage.getItem('token') && token) {
        window.sessionStorage.setItem('token', token)
        await client.resetStore()
      }
    }

    setToken()
  }, [loginToken, socialLoginToken])

  if (!loading && hasRole) {
    navigate('/admin')
    return null
  }

  const handleAppleIDLogin = data => {
    setError(null)

    socialLogin(get(data, 'detail.authorization.id_token'), 'apple')
  }

  const handleFacebookLogin = response => {
    setError(null)

    if (response.status === 'unknown') {
      setError('Innloggingen ble avbrutt')
      return
    }

    socialLogin(get(response, 'authResponse.accessToken'), 'facebook')
  }

  function handleAppleIDError(error) {
    if (error.detail.error === 'popup_closed_by_user') {
    }
    setError('Innloggingen med AppleID feilet')
  }

  function handleLoginSubmit(event) {
    event.preventDefault()
    login()
  }

  const LOGIN = {
    title: 'Logg inn',
    close: true,
    items: [
      {
        id: 'social-login',
        item: 'custom',
        children: (
          <>
            <div
              style={{
                // Hack.... Make a button group component?
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <SignInWithApple
                onSuccess={handleAppleIDLogin}
                onError={handleAppleIDError}
                onClick={() => setError(null)}
              />

              <SignInWithFacebook
                onClick={() => {
                  setError(null)
                  setAuthType('social')
                  FB.login(handleFacebookLogin)
                }}
              />

              {authType === 'social' && error ? <p>{error}</p> : null}
            </div>
          </>
        ),
      },
      {
        item: 'input',
        type: 'email',
        key: 'email',
        label: 'E-post',
        name: 'email',
        value: email,
        onChange: setEmail,
      },
      {
        item: 'input',
        type: 'password',
        key: 'password',
        label: 'Passord',
        name: 'password',
        value: password,
        onChange: setPassword,
      },
      {
        id: 'login-error',
        item: 'warning',
        value: (authType === 'credentials' && error) || null,
      },
    ],
    onSubmit: handleLoginSubmit,
    submit: 'Logg inn',
    submitProps: { disabled: !email.length || !password.length },
  }

  return (
    <>
      <SEO title="Logg inn" />

      {authType === 'social' && error ? <p>{error}</p> : null}

      <Form
        step="login"
        navigation
        handleBack={() => navigate('/')}
        steps={{
          login: LOGIN,
        }}
      />
    </>
  )
}

Page.propTypes = {
  // data: T.shape({
  //  coverImage: T.object.isRequired,
  //  recipesImage: T.object.isRequired,
  //  coursesImage: T.object.isRequired,
  //  products: T.object.isRequired,
  // }).isRequired,
}

// export const query = graphql`
//  query {
//
//  }
// `
