import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'

import SOCIAL_LOGIN_MUTATION from '../queries/social-login'

export default function useSocialLogin(errorState, setAuthType) {
  const [, setError] = errorState
  const [loginSocial] = useMutation(SOCIAL_LOGIN_MUTATION)

  const [token, setToken] = useState(
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('token')
      : null,
  )

  async function doSocialLogin(accessToken, type) {
    setAuthType('social')
    setError(null)

    if (!accessToken || !type) {
      console.warn('Tried to perform social login with either accessToken or type missing.')
      return
    }

    const {
      data: {
        loginSocial: { token = null, error: socialLoginError = null } = {},
      } = {},
    } = await loginSocial({ variables: { type, accessToken } })

    if (socialLoginError) {
      if (socialLoginError.code === 5) {
        setError('En feil skjedde da vi skulle login kontoen')
      } else {
        setError(socialLoginError.message)
      }
      return
    }

    if (socialLoginError) {
      setError(socialLoginError.message)
      return
    }

    setToken(token)
  }

  return { token, login: doSocialLogin }
}
